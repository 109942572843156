import React from "react"
import { Layout } from "../components/Layout"
import {
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner,
  VimeoPlayer,
  GetStartedButtonWhite,
  TryIrisGPTForFreeBanner,
  FeatureSection,
  FeatureSectionRight
} from "../commonComponents"
import { Helmet } from "react-helmet"

export default function AutomaticKnowledgeGeneration() {
    const HeroVideo = () => {
    return (
      <div className="video-player-wrapper" >
        <VimeoPlayer url="https://vimeo.com/1056161484" autoplay="true" loop="true" />
      </div>
    )
}
  return (
    <Layout
      keywords="auto kb,  auto knowledge base, auto knowledge article generation, auto knowledge article creation"
      title="AutoKB: AI-Powered Knowledge Base Generator | Boost Support Efficiency"
      description="Transform support cases into a comprehensive knowledge base with AutoKB. Automatically fill KB gaps, enhance self-service, and empower agents. Try now!"
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/automatic-knowledge-generation/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}

      <section
        id="hero-new-design"

      >
        <div className="container w-100 mw-100 m-0">
            <div className={"row"}>
                <div
                    className={"col-lg-12 col-md-12 col-sm-24 col-xs-24 section-padding-left section-padding-right"}
                    style={{ paddingLeft: "0" }}
                >
                <br/>
                    <h1 className="title-support-new-design" style={{fontWeight: 500, textAlign: "center"}}>
                        <span className="text-highlight">
            AutoKB: </span>AI generated knowledge articles from solved cases
                    </h1>
                    <div className="description-support-new-design w-100" style={{textAlign: "center"}}>
                        Turn solved cases into a powerful knowledge base – automatically. Close the knowledge gap.
                    </div>
                <br/>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>
                   <GetStartedButtonWhite
                   />
                   <LearnMoreButton
                    label="TRY FOR FREE"
                    to="/try-for-free"
                   />
                 </div>

                </div>
                <div className={"col-lg-12 col-md-12 col-sm-24 col-xs-24"} style={{paddingLeft: "12%", paddingRight: "12%"}}>
                    <HeroVideo />
                </div>
            </div>
        </div>
      </section>

      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}
      <br/><br/>
      <FeatureSection
            title="Automated Knowledge Article Generation"
            description="Converts resolved support cases into high-quality, structured knowledge base articles with no effort. Ensures consistency in tone, format, and accuracy across all articles."
            imageUrl="/img/auto-kb.png"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
          />
      <FeatureSectionRight
            title="Gap Analysis in Knowledge Base"
            description="Automatically detects missing or outdated articles by analyzing support case trends. Prioritizes article creation for high-impact issues based on case frequency and urgency."
            imageUrl="/img/gap-analysis.png"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
          />
      <FeatureSection
            title="AI-Powered Content Optimization"
            description="Leverages natural language processing (NLP) to create clear, concise, and actionable articles. Automatically includes step-by-step instructions, FAQs, and troubleshooting tips."
            imageUrl="/img/homeIlustration1.webp"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "80%"
          />
      <FeatureSectionRight
            title="Seamless Integration with Existing Systems"
            description="Integrates with popular CRMs, ticketing systems (e.g., Zendesk, Salesforce, etc.), and knowledge base platforms. Syncs new articles directly into your KB without manual intervention."
            imageUrl="/img/answerFaster.webp"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
          />

      {/* <!-- ======= Testimonials Section ======= --> */}
      <Testimonials />
      {/* <!-- End Testimonials Section --> */}

      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= Rockstar support team Section ======= --> */}


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is Automatic Knowledge Generation?"
                  answer="It's an AI-powered tool that automatically creates knowledge base articles from your resolved support cases."
                />
                <Faq
                  id="faq2"
                  question="How does Automatic Knowledge Generation work?"
                  answer="It analyzes resolved cases, generates articles, identifies gaps in your KB, and then publishes the new article."
                />
                <Faq
                  id="faq3"
                  question="Can I edit the automatically generated articles?"
                  answer="Yes, you can review and edit the articles before publishing them."
                />
                <Faq
                  id="faq4"
                  question="Can I customize the style and format of the generated articles?"
                  answer="Yes, you can tailor the output to match your brand and formatting guidelines."
                />
                <Faq
                  id="faq5"
                  question="How will Automatic Knowledge Generation benefit my customer support team?"
                  answer="It reduces ticket volumes, improves agent efficiency, and enhances customer self-service."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}



      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
